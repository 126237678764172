<template>
  <div class="year-statistic">
    <SectionTitle fontSize="18" hideBtn>
      <template slot="title">
        <p style="font-size: 18px">年度統計</p>
        <p class="text-gray-60 text-sub" style="margin-left: 12px">計算範圍：當年度 1/1 00:00:00 ~ 隔年度 1/31 23:59:59</p>
      </template>
    </SectionTitle>
    <PointStatisticBlock :data="displayDataA" />
    <PointStatisticBlock :data="displayDataB" />
  </div>
</template>

<script setup>
import PointStatisticBlock from '@/components/Report/PointStatisticBlock.vue'
import { GetOriginTotalAmount } from '@/api/shopPoint'
import { useRangeTime } from '@/use/rangeTime'
import store from '@/store'
import { computed, onMounted, ref } from 'vue'
import { get } from 'lodash'

const { getYearRange } = useRangeTime()

const data = ref(null)
const shopId = computed(() => store.getters.shop)

const totalAdd = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD') +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD') +
    get(data.value, 'ADMIN_ADD') +
    get(data.value, 'LOTTERY_AWARD_ADD') +
    get(data.value, 'SERIAL_ACTIVITY_ADD') +
    get(data.value, 'EVENT_PLAYBOOK_SEND') +
    get(data.value, 'EXTEND_IN')
})
const totalUse = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'MEMBER_STORE_USE')
})

const totalReturn = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL') +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL') +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL') +
    get(data.value, 'EXTEND_OUT') +
    get(data.value, 'ADMIN_USE')
})

const totalCancel = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'EXPIRED')
})
const total = computed(() => {
  if (!data.value) return '-'
  const count = totalAdd.value + totalUse.value + totalReturn.value + totalCancel.value
  return count
})

const displayDataA = computed(() => [
  { label: '總發放數', value: totalAdd.value },
  { label: '總折抵數', value: totalUse.value },
])

const displayDataB = computed(() => [
  { label: '總收回數', value: totalReturn.value },
  { label: '總過期數', value: totalCancel.value },
  { label: '未實現數', value: total.value, tooltip: '未實現數 = 總發放數 - 總折抵數 - 總收回數 - 總過期數' },
])

const getOriginTotalAmount = async () => {
  const dates = getYearRange()
  const [res, err] = await GetOriginTotalAmount({
    shopId: shopId.value,
    createStart: dates[0],
    createEnd: dates[1],
  })
  if (err) {
    window.$message.error(err)
    return
  }
  data.value = res
}

onMounted(async () => {
  await getOriginTotalAmount()
})
</script>

<style scoped lang="postcss">

</style>
